.ais-Hits.layout-text .ais-Hits-list {
  @apply md:stl-grid stl-grid-cols-2 stl-gap-4 /*stl-list-disc stl-ml-4*/ stl-ml-0;
}

.ais-Hits.layout-card .ais-Hits-list {
  @apply sm:stl-grid sm:stl-grid-cols-2 lg:stl-grid-cols-4 stl-gap-4 stl-ml-0;
}

.ais-Hits-item {
  @apply stl-w-full stl-m-0 stl-shadow-none stl-border-none stl-p-0;

  /* custom he-arc */
  /*background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='3px' viewBox='0 0 15 3' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' preserveAspectRatio='none'%3E%3Ctitle%3ECouleurs / Gris%3C/title%3E%3Cg id='Symboles' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Contenu-/-Liste-/-Simple-/-Liste' transform='translate(0.000000, -12.000000)' fill='%23292927'%3E%3Cg id='Couleurs-/-Gris' transform='translate(0.000000, 12.000000)'%3E%3Crect id='Rectangle' x='0' y='0' width='15' height='3'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: 0em 0.7em;
  background-repeat: no-repeat;
  @apply stl-pl-5;*/
}

/*.ais-Hits-item::marker {
  @apply stl-text-accent;
}*/

.dashed-title {
  position: relative;
}

@media (min-width: 768px) {
  .dashed-title:before {
    content: "";
    background: red;
    display: inline-block;
    height: 0.35rem;
    position: absolute !important;
    left: 0;
    top: 0.90em;
    width: 2rem;
    transform: translate(-120%, -100%);
  }
}

.ais-Hits.hide-content .entry-content {
  @apply stl-hidden;
}

.ais-Hits.hide-breadcrumb .entry-breadcrumb {
  @apply stl-hidden;
}
