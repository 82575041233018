.ais-Highlight-highlighted {
  @apply stl-not-italic stl-text-black stl-bg-grey-200;
  /*color: #E93842; TODO: accent */
}

.ais-Snippet-highlighted {
  @apply stl-not-italic stl-text-black stl-font-bold stl-bg-white;
  /*color: #E93842; TODO: accent */
}

::selection {
  @apply stl-text-black stl-bg-grey-200;
}

.header {
  @apply stl-min-h-12 stl-py-2 md:stl-px-4 stl-text-white stl-mb-4 stl-bg-gradient-to-r stl-from-blue-600 stl-to-blue-800;
}

.header a {
  @apply stl-text-white;
}

.header-title {
  @apply stl-text-lg stl-font-normal;
}

.header-subtitle {
  @apply stl-text-lg;
}

.container {
  @apply stl-p-2 stl-w-full stl-max-w-7xl stl-my-0 stl-mx-auto stl-box-border;
}

.ais-Highlight * {
  @apply stl-text-md;
}

.ais-RefinementList-labelText {
  @apply stl-ml-1;
}

.ais-Panel-header {
  @apply stl-border-none stl-text-faded stl-mb-0;
}

.ais-Panel--noRefinement {
  @apply stl-hidden;
}

.ais-Pagination-list {
  @apply stl-justify-start stl-mt-4 stl-mx-auto;
}

.ais-Pagination-item .ais-Pagination-link {
  @apply stl-border-none stl-px-1 stl-text-black stl-underline hover:stl-bg-white hover:stl-text-black focus:stl-bg-white focus:stl-text-black;
}

.ais-Pagination-item--selected .ais-Pagination-link {
  @apply stl-bg-transparent stl-text-black stl-font-bold;
}

.ais-Pagination--noRefinement {
  @apply stl-hidden md:stl-block md:stl-opacity-0;
}

.ais-RefinementList-labelText {
  @apply stl-text-black;
}

.btn-primary,
.dropdown {
  @apply stl-inline-block stl-border stl-border-accent stl-px-2 stl-py-1 stl-bg-white hover:stl-no-underline;
}

.btn-primary.is-arrowed,
.dropdown {
  @apply stl-pr-5 md:stl-pr-10 stl-bg-no-repeat;
  background-position: 97% 55%;
  /*background-size: 35px 16px;*/
}

.btn-primary.is-arrowed {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='15px' height='13px' viewBox='0 0 15 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symboles' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Dynamiques-/-Icones-/-Mini-/-Fleche-directe-/-Noir' transform='translate(0.000000, -2.000000)' stroke='%23E30613'%3E%3Cline x1='1.5' y1='8.5' x2='12.5' y2='8.5' id='Line-9' stroke-width='1.2' stroke-linecap='square'%3E%3C/line%3E%3Cpolyline id='Path-4' stroke-width='1.3' points='7.46856637 2.5 13.4685664 8.5 7.46856637 14.5'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.dropdown {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='21px' viewBox='0 0 15 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EDynamiques / Icones / Mini / Fleche bas / Noir%3C/title%3E%3Cg id='Dynamiques-/-Icones-/-Mini-/-Fleche-bas-/-Noir' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='↗' fill='%23292927' fill-rule='nonzero' transform='translate(7.498175, 8.778175) rotate(135.000000) translate(-7.498175, -8.778175) ' points='3.31336938 13.9246212 11.2708478 5.95940656 11.2708478 12.4710137 12.6446212 12.4710137 12.6446212 3.63172798 3.803408 3.63172798 3.803408 4.99690656 10.3092064 4.99690656 2.35172798 12.9621212'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
}
