/* Color management */

.theme-black {
  --accent: #000000;
  --accent-shadow: #161616;
  --accent-shadow-bold: #161616;
}

:root,
.theme-he-arc,
[data-theme="6"] {
  --accent: #E30613;
  --accent-pale: #FDA4A9;
  --accent-80: #f92733;
  --accent-shadow: #E30613;
  --accent-shadow-bold: #E30613;
}

.theme-gestion,
[data-theme="2"] {
  --accent: #0679C6;
  --accent-pale: #D9EBF7;
  --accent-80: #119bf7;
  --accent-shadow: #4F9FD5;
  --accent-shadow-bold: #1D85CA;
}

.theme-conservation-restauration,
[data-theme="5"] {
  --accent: #Ef8214;
  --accent-pale: #FFCDB8;
  --accent-80: #f29b43;
  --accent-shadow: #da750f;
  --accent-shadow-bold: #da750f;
}

.theme-ingenierie,
[data-theme="3"] {
  --accent: #E93842;
  --accent-pale: #FBDDDF;
  --accent-80: #ed5f67;
  --accent-shadow: #E30613;
  --accent-shadow-bold: #E93842;
}

.theme-sante,
[data-theme="4"] {
  --accent: #5C7A33;
  --accent-pale: #B7D293;
  --accent-80: #7fa946;
  --accent-shadow: #8BA06E;
  --accent-shadow-bold: #6B8646;
}
